import React from 'react';
import PropTypes from 'prop-types';
import { compose, mapProps } from '@shakacode/recompose';
import classNames from 'classnames';

import countries from '../utils/countries';
import { withIntl } from '../utils/withIntl';
import { withPopmenuConfig } from '../utils/withPopmenuConfig';
import { withStyles } from '../utils/withStyles';

// Prevent server-side error when importing DOM-dependent library
let MapboxGeocoder = null;
if (typeof window !== 'undefined') {
  MapboxGeocoder = require('@mapbox/mapbox-gl-geocoder');
}

const styles = {
  geocoder: {
    '& .mapbox-gl-geocoder--no-results': {
      padding: '16px',
      textAlign: 'center',
    },
    '& .mapboxgl-ctrl-geocoder': {
      '& .mapboxgl-ctrl-geocoder--powered-by': {
        '& a': {
          color: 'inherit',
        },
        fontSize: '0.7rem',
        padding: '10px 14px',
      },
      '& input:focus': {
        border: '1px solid black',
        borderRadius: '4px',
        boxShadow: '0 0 0 1px rgb(0 0 0) !important',
      },
      '& ul': {
        borderRadius: '0 0 4px 4px',
        boxShadow: '0 0 0 1px rgb(195 195 195)',
        fontSize: '1rem',
        marginTop: '-2px',
      },
      '& ul > li > a': {
        lineHeight: '1.5em',
        padding: '10px 18px',
        textDecoration: 'none',
        whiteSpace: 'pre-line',
      },
      borderRadius: '4px',
      boxShadow: '0 0 0 1px rgb(195 195 195)',
      position: 'relative',
    },
    '& .mapboxgl-ctrl-geocoder input[type="text"]': {
      fontSize: '1rem',
      height: '54px',
    },
    '& .mapboxgl-ctrl-geocoder input[type="text"]:hover': {
      borderRadius: '4px',
      boxShadow: '0 0 0 1px rgb(0 0 0)',
    },
    '& .mapboxgl-ctrl-geocoder:focus': {
      boxShadow: 'none',
    },
    '& .mapboxgl-ctrl-geocoder--button': {
      display: 'none',
    },
    '& .mapboxgl-ctrl-geocoder--icon-loading': {
      display: 'none !important',
    },
    '& .mapboxgl-ctrl-geocoder--icon-search': {
      left: '13px',
      position: 'absolute',
      top: '18px',
      transform: 'scale(1.2)',
    },
    '& .mapboxgl-ctrl-geocoder--input::placeholder': {
      opacity: 0.7,
    },
    '& .mapboxgl-ctrl-geocoder--pin-right': {
      position: 'absolute',
      right: '13px',
      top: '18px',
    },
    position: 'relative',
    textAlign: 'left',
  },
  geocoderWithHiddenSearchIcon: {
    '& .mapboxgl-ctrl-geocoder--icon-search': {
      display: 'none !important',
    },
    '& .mapboxgl-ctrl-geocoder--input': {
      paddingLeft: '10px !important',
    },
  },
};

class MapGeocoder extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      render: false,
    };
    this.initGeocoder = this.initGeocoder.bind(this);
    this.geocoder = null;
  }

  componentDidMount() {
    this.setState({ render: true });
    this.initTimeout = setTimeout(this.initGeocoder, 100);
  }

  componentWillUnmount() {
    clearTimeout(this.initTimeout);
    this.geocoder = null;
  }

  initGeocoder() {
    if (this.geocoder) {
      return;
    }

    this.geocoder = new MapboxGeocoder({
      accessToken: this.props.mapboxPublicKey,
      // Countries must be CSV of ISO 3166 country abbreviations
      // which we use as keys of the Restaurant::Location.country enum
      countries: Object.keys(countries).join(', '),
      fitBounds: false,
      flyTo: false,
      limit: this.props.suggestionsLimit,
      placeholder: this.props.placeholder ? this.props.placeholder : this.props.t('consumer.search_bar_placeholder'),
    });

    // Update user location when selecting from search box and reset map bounds
    this.geocoder.on('result', (e) => {
      const [geoLng, geoLat] = e.result.geometry.coordinates;

      if (this.props.onUserLocationChange) {
        this.props.onUserLocationChange(geoLat, geoLng);
      }

      if (this.props.centerViewPortOnClosestLocation) {
        this.props.centerViewPortOnClosestLocation(geoLat, geoLng);
      } else if (this.props.onResultCallback) {
        this.props.onResultCallback(e);
      } else {
        this.props.fitBounds();
      }
    });

    this.geocoder.on('clear', () => {
      if (this.props.onClear) {
        this.props.onClear();
      }
    });

    this.geocoder.on('results', (e) => {
      if (this.props.onChange) {
        this.props.onChange(e);
      }
    });

    this.geocoder.addTo(`#${this.props.id}`);
    this.geocoder.setInput(this.props.value || '');

    if (this.props.onInit) {
      this.props.onInit(this.geocoder);
    }
  }

  render() {
    if (!this.state.render || !MapboxGeocoder) {
      return null;
    }
    return (
      <div className="search-wrap">
        <div
          className={classNames([
            this.props.classes.geocoder,
            this.props.hideSearchIcon && this.props.classes.geocoderWithHiddenSearchIcon,
          ])}
          id={this.props.id}
        />
      </div>
    );
  }
}

MapGeocoder.defaultProps = {
  coordinates: null,
  fitBounds: () => {},
  hideSearchIcon: false,
  onChange: null,
  onClear: null,
  onResultCallback: null,
  placeholder: null,
  suggestionsLimit: undefined,
  value: null,
};

MapGeocoder.propTypes = {
  classes: PropTypes.object.isRequired,
  coordinates: PropTypes.object,
  fitBounds: PropTypes.func,
  hideSearchIcon: PropTypes.bool,
  id: PropTypes.string.isRequired,
  mapboxPublicKey: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onClear: PropTypes.func,
  onResultCallback: PropTypes.func,
  placeholder: PropTypes.string,
  suggestionsLimit: PropTypes.number,
  t: PropTypes.func.isRequired,
  value: PropTypes.string,
};

export default compose(
  withStyles(styles),
  withIntl,
  withPopmenuConfig,
  mapProps(({ popmenuConfig, ...props }) => ({
    ...props,
    mapboxPublicKey: popmenuConfig.mapboxPublicKey,
  })),
)(MapGeocoder);
